export function redirectTo(url) {
  window.location.replace(url);
}

export function getCurrentPath() {
  let location = window.location
  return location.protocol + '//' + location.host + location.pathname;
}

export function isEmptyString(string) {
  return (!string || 0 === (string.trim()).length); 
}

export function getLocaleText(locale) {
  const localeTextMapping = { zh: "中文", en: "英文"};
  return localeTextMapping[locale];
}

export function getInitialState() {
  return JSON.parse(document.getElementById('initial-state').getAttribute('data'));
}
